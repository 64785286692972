<template>
  <div>
    <v-form ref="form">
      <v-card elevation="2" outlined class="mx-auto" max-width="800">
        <v-card-title>Procesar datos</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="file"
            show-size
            small-chips
            truncate-length="20"
            label="Archivo"
            accept=".csv"
            :rules="[rules.required]"
            :disabled="submited"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              color="indigo darken-4"
              dark
              @click="submit"
              :disabled="submited"
            >
              Procesar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      type="success"
      class="mt-6 mx-auto"
      max-width="300"
      elevation="5"
      transition="scroll-y-transition"
      :value="alert"
    >
      Datos Procesados
    </v-alert>
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          CERRAR
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { firebase } from "@/firebase";
import Papa from "papaparse";

export default {
  data() {
    return {
      submited: false,
      file: null,
      alert: false,
      snackbar: false,
      snackbar_text: null,
      rules: {
        required: (value) => !!value || "Requerido.",
      },
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.submited = true;
      const json = [];
      const storageRef = firebase.storage().ref();
      const adetRef = storageRef.child("adet.json");

      Papa.parse(this.file, {
        header: true,
        encoding: "ISO-8859-1",
        skipEmptyLines: true,
        complete: async (results) => {
          //Verificamos que el header este correcto
          const fields = [
            "UN",
            "SERVICIO",
            "SENTIDO",
            "PERIODO",
            "COD_PARADA",
            "COD_SIMT",
            "COMUNA",
            "NOM_PARADA",
            "FECHA_PROG",
            "NUM_MED",
            "FECHA_MED",
            "T_ESPERA_REAL",
            "T_ESPERA_ACEP",
            "RESULTADO",
            "TER_TEA",
            "ESTADO",
            "OBS",
          ];
          const result = fields.every((field) =>
            results.meta["fields"].includes(field)
          );
          if (!result) {
            this.snackbar = true;
            this.snackbar_text = "Archivo CSV sin el formato requerido";
            this.submited = false;
            return;
          }
          let i = 1;
          for (const result of results.data) {
            i++;
            if (result.UN === "") continue;
            result.FECHA_PROG = result.FECHA_PROG.replace(/-/g, "/");
            let dateParts = result.FECHA_PROG.split("/");
            const fecha_prog = new Date(
              +dateParts[2],
              dateParts[1] - 1,
              +dateParts[0]
            );
            if (!fecha_prog || isNaN(fecha_prog.getTime())) {
              this.snackbar = true;
              this.snackbar_text = `Archivo CSV contiene fecha programada sin el formato requerido [DD/MM/YYYY] ${result.FECHA_PROG} - Linea ${i}`;
              this.submited = false;
              return;
            }
            result.FECHA_PROG_TIMESTAMP = fecha_prog.getTime();
            result.FECHA_MED = result.FECHA_MED.replace(/-/g, "/");
            dateParts = result.FECHA_MED.split("/");
            const fecha_med = new Date(
              +dateParts[2],
              dateParts[1] - 1,
              +dateParts[0]
            );
            if (!fecha_med || isNaN(fecha_med.getTime())) {
              this.snackbar = true;
              this.snackbar_text =
                "Archivo CSV contiene fecha medición sin el formato requerido [DD/MM/YYYY]";
              this.submited = false;
              return;
            }
            result.FECHA_MED_TIMESTAMP = fecha_med.getTime();
            if (
              ![
                "MEDIDO",
                "RESULTADO PRELIMINAR",
                "ANULADO",
                "SIN RESULTADO",
              ].includes(result.ESTADO.toUpperCase())
            ) {
              this.snackbar = true;
              this.snackbar_text = `Archivo CSV contiene estados invalidos ${result.ESTADO.toUpperCase()}`;
              this.submited = false;
              return;
            }
            let estado_num = null;
            switch (result.ESTADO.toUpperCase()) {
              case "MEDIDO":
                estado_num = 1;
                break;
              case "RESULTADO PRELIMINAR":
                estado_num = 2;
                break;
              case "ANULADO":
                estado_num = 3;
                break;
              case "SIN RESULTADO":
                estado_num = 4;
                break;
            }
            json.push({
              user_uid: this.$store.state.user.uid,
              user_name: this.$store.state.user.name,
              fecha_creacion: new Date().toLocaleDateString("en-GB", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }),
              hora_creacion: new Date().toLocaleTimeString([], {
                hour12: false,
              }),
              timestamp_creacion: Date.now() / 1000,
              fechahora_creacion: `${new Date().toLocaleDateString("en-GB", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })} ${new Date().toLocaleTimeString([], { hour12: false })}`,
              un: result.UN,
              servicio: result.SERVICIO.toUpperCase(),
              sentido: result.SENTIDO.toUpperCase(),
              periodo: result.PERIODO.toUpperCase(),
              cod_parada: result.COD_PARADA.toUpperCase(),
              cod_simt: result.COD_SIMT.toUpperCase(),
              comuna: result.COMUNA.toUpperCase(),
              nom_parada: result.NOM_PARADA.toUpperCase(),
              fecha_prog: result.FECHA_PROG.toUpperCase(),
              fecha_prog_timestamp: result.FECHA_PROG_TIMESTAMP,
              num_med: result.NUM_MED.toUpperCase(),
              estado: estado_num,
              estado_desc: result.ESTADO.toUpperCase(),
              fecha_med: result.FECHA_MED.toUpperCase(),
              fecha_med_timestamp: result.FECHA_MED_TIMESTAMP,
              espera_real: parseFloat(result.T_ESPERA_REAL.replace(",", ".")),
              espera_acep: parseFloat(result.T_ESPERA_ACEP.replace(",", ".")),
              resultado: result.RESULTADO.toUpperCase(),
              ter_tea: parseFloat(result.TER_TEA.replace(",", ".")),
              obs: result.OBS.toUpperCase(),
            });
          }
          adetRef.putString(JSON.stringify(json)).then(() => {
            console.log("Subido");
            this.alert = true;
            setTimeout(
              () =>
                this.$router.push({
                  name: "home",
                }),
              2000
            );
          });
        },
      });
    },
  },
};
</script>
